<!-- 短视频 -->
<template>
  <div class="bingeWatch">
    <van-tabs class="tabs" v-model="active" @change="change">
      <van-tab title="收藏">
        <CollectList :isAcg="true" :moduleSort="1"></CollectList>
      </van-tab>
      <van-tab title="历史">
        <HistoryList :isAcg="true"></HistoryList>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// import VideoModuleItem from "@/components/videoModuleItem/index.vue";
import CollectList from "./collectList/index.vue";
import HistoryList from "./historyList/index.vue";
import store from "@/store";

export default {
  name: 'BingeWatch',
  components: {
    CollectList,
    HistoryList,
  },
  data(){
    return {
      active:0,
      itemModules: null,
    }
  },
  mounted() {
    let modulesList = this.$store.getters.modulesInfo?.sp || [];
    if(modulesList && modulesList.length > 0){
      this.itemModules = modulesList[0];
    }
  },methods:{
    change(index){
      store.commit('app/SET_SCROLL_INFO', {
        key: this.$route.path,
        index: index,
      });
    }
  }
}
</script>

<style scoped lang="scss">
.bingeWatch{
  .tabs{
    margin-top: 20px;

    /deep/ .van-tabs__nav{
      background: transparent;
    }

    /deep/ .van-tab{
      font-size: 10px;
      padding: 0;
      border: none;
      color: #B09FD9;
      font-weight: 400;
    }

    /deep/ .van-tab--active{
      border-radius: 8px;
      background: #777;
      border: none;
      font-weight: 400;
      color: #FFF;
    }

    /deep/ .videoBox{
      margin-bottom: 12px;
    }

    /deep/ .van-tabs__line{
      display: none;
    }

    /deep/ .van-tabs__wrap{
      border-radius: 8px;
      height: 26px;
      margin: 0 23px;
      background: #333;
      border: none;
      padding: 2px 4px;
    }

    /deep/ .coverBox{
      background: #0C0C0C;

      .title{
        color: #FFFFFF;
      }
    }
  }
}
</style>